var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "animated fadeIn",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            {
              staticClass:
                "d-flex justify-content-start align-items-center pb-2",
            },
            [
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  { staticClass: "wrap-status justify-content-start" },
                  [
                    _c(
                      "div",
                      {
                        class: `status ${_vm.accomodation.statusClass}`,
                        staticStyle: { "font-size": "0.7rem" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.getTextOption(
                                _vm.accomodation.status,
                                _vm.statusOptions
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-end align-items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-booking font-weight-bold mr-3" },
                      [_vm._v(_vm._s(_vm.FormMSG(1, "Booking n°")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "number-booking py-2 px-4 font-weight-bold",
                      },
                      [_vm._v(_vm._s(_vm.accomodation.acc.reference))]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" + _vm._s("General") + "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      { staticClass: "pb-2" },
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                          [
                            _vm.action === _vm.ACTION.UPDATE
                              ? _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(2, "Name") } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column content-form-text",
                                        staticStyle: {
                                          "align-items": "normal",
                                        },
                                      },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.accomodation.user.firstName
                                            ) +
                                              " " +
                                              _vm._s(_vm.accomodation.user.name)
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "text-department" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.accomodation.user
                                                  .departmentName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(49, "Publisher"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column content-form-text",
                                        staticStyle: {
                                          "align-items": "normal",
                                        },
                                      },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.accomodation.publisher
                                                .firstName
                                            ) +
                                              " " +
                                              _vm._s(
                                                _vm.accomodation.publisher.name
                                              )
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "text-department" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.accomodation.publisher
                                                  .departmentName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "8", xl: "8" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { label: _vm.FormMSG(3, "Description") },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "content-form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.accomodation.acc.description)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(4, "Booking")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            attrs: {
                              sm: `${
                                _vm.accomodation.acc.category === 1 ? 6 : 12
                              }`,
                              md: `${
                                _vm.accomodation.acc.category === 1 ? 6 : 12
                              }`,
                              lg: `${
                                _vm.accomodation.acc.category === 1 ? 3 : 4
                              }`,
                              xl: `${
                                _vm.accomodation.acc.category === 1 ? 3 : 4
                              }`,
                            },
                          },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(44, "Category") } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "content-form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTextOption(
                                          _vm.accomodation.acc.category,
                                          _vm.categoryOptions
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.accomodation.acc.category === 1
                          ? _c(
                              "b-col",
                              [
                                _c("transport-type-selector", {
                                  ref: "transportTypeSelectorAccomodation",
                                  attrs: {
                                    "hide-freight-type": "",
                                    "data-type": _vm.accomodation.acc.type,
                                    "data-detail":
                                      _vm.accomodation.acc.coTwoType,
                                    "data-additional-detail":
                                      _vm.accomodation.acc.bookingStatus,
                                    "hide-special-vehicles": "",
                                    "disable-form": "",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.accomodation.acc.category !== 1
                          ? _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "4", xl: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(6, "Type") } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "content-form-text" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.getTextOption(
                                                _vm.accomodation.acc.type,
                                                _vm.accomodation.acc
                                                  .category === 1
                                                  ? _vm.travelTypeOptions
                                                  : _vm.accomodationTypeOptions
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.accomodation.acc.category !== 1
                          ? _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "4", xl: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(8, "No. of bedrooms"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "content-form-text" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.accomodation.acc.nbBedroom)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.action === _vm.ACTION.UPDATE
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "6", md: "6", lg: "3", xl: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(9, "Start date"),
                                    },
                                  },
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        "available-dates": {
                                          start: new Date(),
                                          end: null,
                                        },
                                        locale: _vm.lang,
                                        masks: _vm.masks,
                                      },
                                      on: { dayclick: _vm.handleStartDate },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({
                                              inputValue,
                                              togglePopover,
                                            }) {
                                              return [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "br-none",
                                                      attrs: {
                                                        value: inputValue,
                                                        readonly: "",
                                                      },
                                                    }),
                                                    _c(
                                                      "b-input-group-append",
                                                      [
                                                        _c(
                                                          "b-input-group-text",
                                                          {
                                                            staticClass:
                                                              "cursor-pointer bg-color-white input-group-text-bg-white",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return togglePopover()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "Calendar"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "rgba(6, 38, 62, .6)",
                                                                  size: 18,
                                                                  "stroke-width": 2.25,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1408384640
                                      ),
                                      model: {
                                        value: _vm.accomodation.startDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.accomodation,
                                            "startDate",
                                            $$v
                                          )
                                        },
                                        expression: "accomodation.startDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "6", md: "6", lg: "3", xl: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(10, "Start time"),
                                    },
                                  },
                                  [
                                    _c("input-date-time-picker", {
                                      attrs: {
                                        uiid: "accst",
                                        type: "text",
                                        placeholder: "hh:mm",
                                        item: _vm.accomodation.startTime,
                                        nbHour:
                                          _vm.rangeHour[_vm.WORKTYPE.daily],
                                        nbMinute:
                                          _vm.rangeMinute[_vm.WORKTYPE.daily],
                                        isEditable: () => {
                                          return true
                                        },
                                        fieldName: "startTime",
                                        workType: _vm.WORKTYPE.daily,
                                        iconColor: "rgba(6, 38, 62, 0.6)",
                                        iconStrokeWidth: 2.25,
                                        iconSize: 18,
                                        iconBg:
                                          "cursor-pointer bg-color-white input-group-text-bg-white",
                                      },
                                      on: { change: _vm.handleChangeHourMin },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "6", md: "6", lg: "3", xl: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(11, "End date"),
                                    },
                                  },
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        "available-dates": {
                                          start: new Date(),
                                          end: null,
                                        },
                                        locale: _vm.lang,
                                        masks: _vm.masks,
                                      },
                                      on: { dayclick: _vm.handleEndDate },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({
                                              inputValue,
                                              togglePopover,
                                            }) {
                                              return [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "br-none",
                                                      attrs: {
                                                        value: inputValue,
                                                        readonly: "",
                                                      },
                                                    }),
                                                    _c(
                                                      "b-input-group-append",
                                                      [
                                                        _c(
                                                          "b-input-group-text",
                                                          {
                                                            staticClass:
                                                              "cursor-pointer bg-color-white input-group-text-bg-white",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return togglePopover()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "Calendar"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "rgba(6, 38, 62, .6)",
                                                                  size: 18,
                                                                  "stroke-width": 2.25,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1408384640
                                      ),
                                      model: {
                                        value: _vm.accomodation.endDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.accomodation,
                                            "endDate",
                                            $$v
                                          )
                                        },
                                        expression: "accomodation.endDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "6", md: "6", lg: "3", xl: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(12, "End time"),
                                    },
                                  },
                                  [
                                    _c("input-date-time-picker", {
                                      attrs: {
                                        uiid: "accet",
                                        type: "text",
                                        placeholder: "hh:mm",
                                        item: _vm.accomodation.endTime,
                                        nbHour:
                                          _vm.rangeHour[_vm.WORKTYPE.daily],
                                        nbMinute:
                                          _vm.rangeMinute[_vm.WORKTYPE.daily],
                                        isEditable: () => {
                                          return true
                                        },
                                        fieldName: "endTime",
                                        workType: _vm.WORKTYPE.daily,
                                        iconColor: "rgba(6, 38, 62, 0.6)",
                                        iconStrokeWidth: 2.25,
                                        iconSize: 18,
                                        isSubmitted: _vm.isSubmitted,
                                        error:
                                          !_vm.$v.accomodation.endTime
                                            .hourMinuteValid,
                                        iconBg:
                                          "cursor-pointer bg-color-white input-group-text-bg-white",
                                      },
                                      on: { change: _vm.handleChangeHourMin },
                                    }),
                                    _vm.isSubmitted &&
                                    !_vm.$v.accomodation.endTime.hourMinuteValid
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    13,
                                                    "End time must be greater than start time"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(9, "Start date"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-form-text",
                                        staticStyle: { background: "#fbf5e5" },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.getLiteralDate(
                                                _vm.accomodation.startDate,
                                                _vm.accomodation.startTime
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(11, "End date"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-form-text",
                                        staticStyle: { background: "#fbf5e5" },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.getLiteralDate(
                                                _vm.accomodation.endDate,
                                                _vm.accomodation.endTime
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c(
                      "b-row",
                      { staticClass: "pb-2" },
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(14, "Supplier") } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "content-form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.accomodation.acc.supplier.name)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.accomodation.confirmation.length > 0
                          ? _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _vm.action === _vm.ACTION.UPDATE
                                  ? _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            attrs: {
                                              cols: `${
                                                _vm.$screen.width > 576 ? 10 : 9
                                              }`,
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    15,
                                                    "Attachment"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-file", {
                                                  ref: "accomodationFile",
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    accept: _vm.acceptFiles,
                                                    placeholder:
                                                      "Choose a file or drop it here ...",
                                                    "browse-text": "Browse",
                                                    "drop-placeholder":
                                                      "Drop file here ...",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleChangeFileToUploaded,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.accomodation
                                                        .fileToUploaded,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.accomodation,
                                                        "fileToUploaded",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "accomodation.fileToUploaded",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticStyle: {
                                              "margin-top": "28px",
                                            },
                                            attrs: {
                                              cols: `${
                                                _vm.$screen.width > 576 ? 2 : 3
                                              }`,
                                            },
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.top",
                                                        modifiers: {
                                                          hover: true,
                                                          top: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn bg-transparent border-0",
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "sm",
                                                      title:
                                                        _vm.accomodation
                                                          .fileName,
                                                    },
                                                    on: {
                                                      click: _vm.viewAttachment,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "Paperclip"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#225CBD",
                                                          size: 20,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.top",
                                                        modifiers: {
                                                          hover: true,
                                                          top: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn bg-transparent border-0",
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "sm",
                                                      title: _vm.FormMSG(
                                                        36,
                                                        "Delete"
                                                      ),
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.removeAttachment,
                                                    },
                                                  },
                                                  [
                                                    _c(_vm.getLucideIcon("X"), {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#EA4E2C",
                                                        size: 22,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                15,
                                                "Attachment"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                    },
                                                  },
                                                ],
                                                staticClass:
                                                  "btn bg-transparent border-0",
                                                attrs: {
                                                  variant: "primary",
                                                  size: "sm",
                                                  title:
                                                    _vm.accomodation.fileName,
                                                },
                                                on: {
                                                  click: _vm.viewAttachment,
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#225CBD",
                                                      size: 20,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          : _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _vm.action === _vm.ACTION.UPDATE
                                  ? _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(15, "Attachment"),
                                        },
                                      },
                                      [
                                        _c("b-form-file", {
                                          ref: "accomodationFile",
                                          staticClass: "mb-2",
                                          attrs: {
                                            accept: _vm.acceptFiles,
                                            placeholder:
                                              "Choose a file or drop it here ...",
                                            "browse-text": "Browse",
                                            "drop-placeholder":
                                              "Drop file here ...",
                                          },
                                          on: {
                                            change:
                                              _vm.handleChangeFileToUploaded,
                                          },
                                          model: {
                                            value:
                                              _vm.accomodation.fileToUploaded,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.accomodation,
                                                "fileToUploaded",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "accomodation.fileToUploaded",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.accomodation.acc.category === 1
                                ? _vm.FormMSG(17, "Route")
                                : _vm.FormMSG(18, "Place")
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm.accomodation.acc.category === 1
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(19, "From address"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "content-form-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.accomodation.acc.fromAddress
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(20, "To address"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "content-form-text" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.accomodation.acc.toAddress)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        21,
                                        "Accommodation name"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "content-form-text" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.accomodation.acc.name)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(22, "Address"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "content-form-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.rendAddress(
                                              _vm.accomodation.acc.address
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.accomodation.acc.category === 1
                          ? _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "3", xl: "3" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(23, "Total distance"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            disabled: "",
                                            value:
                                              _vm.accomodation.acc
                                                .totalDistance,
                                            id: "total-distance",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticStyle: {
                                                  background: "#47c7bf",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                    staticStyle: {
                                                      color: "#fff",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.distanceUnit)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          {
                            attrs: {
                              sm: "12",
                              md: "12",
                              lg: `${
                                _vm.accomodation.acc.category === 1 ? 9 : 12
                              }`,
                              xl: `${
                                _vm.accomodation.acc.category === 1 ? 9 : 12
                              }`,
                            },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.FormMSG(
                                    24,
                                    "Special instructions"
                                  ),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "content-form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.accomodation.acc.specialInstruction
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                    staticStyle: {
                      "background-color": "rgba(248, 249, 251, 0.5)",
                    },
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(25, "Communication")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm.accomodation.status === 0
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass:
                                  "d-flex justify-content-start align-items-center",
                                attrs: { sm: "5", md: "5", lg: "3", xl: "3" },
                              },
                              [
                                _c("h6", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          26,
                                          "Publish and Send details by"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "2", md: "2", lg: "6", xl: "6" } },
                              [
                                _c("div", {
                                  staticClass: "divider-dotted",
                                  style: `${
                                    _vm.$screen.width > 576
                                      ? "margin: 12px 0 0 0"
                                      : "margin: 4px 0 8px 0"
                                  }`,
                                }),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-start",
                                attrs: { sm: "4", md: "5", lg: "3", xl: "3" },
                              },
                              [
                                _vm.accomodation &&
                                _vm.accomodation.acc.publishedOn ===
                                  "0001-01-01T00:00:00Z"
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "w-138-px",
                                        attrs: {
                                          size: "md",
                                          variant: "outline-secondary",
                                          block: "",
                                        },
                                        on: { click: _vm.publishAccommodation },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-center align-items-center",
                                          },
                                          [
                                            _c("b-spinner", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.watingPublishDelivery,
                                                  expression:
                                                    "watingPublishDelivery",
                                                },
                                              ],
                                              attrs: { small: "" },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                class: `${
                                                  _vm.watingPublishDelivery
                                                    ? "pl-2"
                                                    : ""
                                                }`,
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        75,
                                                        "Send to Users"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.accomodation.status === 0
                      ? _c(
                          "b-row",
                          { staticClass: "d-flex justify-content-center pb-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  sm: "12",
                                  md: "12",
                                  lg: "8",
                                  xl: "6",
                                  "offset-sm": "0",
                                },
                              },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        class: `${
                                          _vm.$screen.width > 576
                                            ? "d-flex justify-content-center"
                                            : "d-flex justify-content-start"
                                        }`,
                                        attrs: { sm: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              id: "toEmail",
                                            },
                                            on: { change: _vm.onChangeEmail },
                                            model: {
                                              value: _vm.accomodation.toEmail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.accomodation,
                                                  "toEmail",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "accomodation.toEmail",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(28, "Email")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        class: `${
                                          _vm.$screen.width > 576
                                            ? "d-flex justify-content-center"
                                            : "d-flex justify-content-start"
                                        }`,
                                        attrs: { sm: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg", id: "toSms" },
                                            on: { change: _vm.onChangeSms },
                                            model: {
                                              value: _vm.accomodation.toSms,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.accomodation,
                                                  "toSms",
                                                  $$v
                                                )
                                              },
                                              expression: "accomodation.toSms",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    29,
                                                    "Text message"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        class: `${
                                          _vm.$screen.width > 576
                                            ? "d-flex justify-content-center"
                                            : "d-flex justify-content-start"
                                        }`,
                                        attrs: { sm: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              id: "toWhatsApp",
                                            },
                                            on: {
                                              change: _vm.onChangeWhatsApp,
                                            },
                                            model: {
                                              value:
                                                _vm.accomodation.toWhatsApp,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.accomodation,
                                                  "toWhatsApp",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "accomodation.toWhatsApp",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(30, "WhatsApp")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.unknownDevice
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "invalid-feedback text-center pb-2",
                                        staticStyle: { "margin-top": "-2px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t* " +
                                            _vm._s(
                                              _vm.FormMSG(
                                                31,
                                                "Please, select one or more device(s) to send the details."
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "b-row",
                          { staticClass: "d-flex justify-content-center pb-3" },
                          [
                            _c("b-col", { attrs: { sm: "12" } }, [
                              _vm.accomodation.status > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "text-purple text-center",
                                      class: `${
                                        _vm.accomodation.status === 0
                                          ? "pt-1"
                                          : "pt-0"
                                      }`,
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(34, "Sent on")) +
                                          " " +
                                          _vm._s(_vm.getPublishedDate()) +
                                          " " +
                                          _vm._s(
                                            _vm.getDeviceName().length > 0
                                              ? _vm.FormMSG(35, "via")
                                              : ""
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.getDeviceName()) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center mt-2",
                  style: `display: ${
                    _vm.displayAddBtn ? "block" : "none"
                  } !important;`,
                  attrs: {
                    sm: "4",
                    md: "4",
                    lg: "4",
                    xl: "4",
                    "offset-sm": "5",
                    "offset-md": "5",
                    "offset-lg": "5",
                    "offset-xl": "5",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      ref: "submit",
                      staticClass: "w-138-px",
                      attrs: {
                        type: "submit",
                        size: "md",
                        variant: "outline-primary",
                        block: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(37, "Save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }