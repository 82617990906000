var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Accommodation Added"),
        "ok-only": "",
        "ok-variant": "success",
        size: "md",
        centered: "",
        "ok-title": _vm.FormMSG(2, "Ok"),
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: { hidden: _vm.emitEventClose },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "alert alert-success d-flex align-items-center" },
        [
          _c(
            "div",
            [
              _c(_vm.getLucideIcon("CheckCircle"), {
                tag: "component",
                attrs: { size: 26 },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ml-3", staticStyle: { "font-size": "1.1rem" } },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    _vm.FormMSG(
                      3,
                      'Your accommodation was added successfully, but not published yet. You can publish it now (or later) by clicking the "Send details" button.'
                    )
                  ) +
                  "\n\t\t"
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }