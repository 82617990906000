var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "animated fadeIn",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "d-flex align-items-center pb-2" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _vm.enableBtnReport
                    ? _c(
                        "b-button",
                        {
                          staticClass: "w-138-px",
                          attrs: {
                            size: "md",
                            variant: "secondary",
                            block: "",
                          },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(1, "Reports")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-end align-items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-booking font-weight-bold mr-3" },
                      [_vm._v(_vm._s(_vm.FormMSG(2, "Booking n°")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "number-booking py-2 px-4 font-weight-bold",
                      },
                      [_vm._v(_vm._s(_vm.accomodation.reference))]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(3, "General")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      { class: `${_vm.selectedUser.length > 0 ? "" : "mb-2"}` },
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(7, "Person") } },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    multiple: false,
                                    options: _vm.users,
                                    "disable-branch-nodes": true,
                                    placeholder: _vm.FormMSG(
                                      8,
                                      "Select a person"
                                    ),
                                  },
                                  on: { select: _vm.personChanged },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "option-label",
                                      fn: function ({ node }) {
                                        return _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "treeselect-label",
                                              attrs: { title: node.label },
                                            },
                                            [_vm._v(_vm._s(node.label))]
                                          ),
                                        ])
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.selectedPerson,
                                    callback: function ($$v) {
                                      _vm.selectedPerson = $$v
                                    },
                                    expression: "selectedPerson",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { label: _vm.FormMSG(9, "Department") },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    multiple: false,
                                    options: _vm.departments,
                                    "disable-branch-nodes": true,
                                    placeholder: _vm.FormMSG(
                                      10,
                                      "Select persons from department"
                                    ),
                                  },
                                  on: { select: _vm.departmentChanged },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "option-label",
                                      fn: function ({ node }) {
                                        return _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "treeselect-label",
                                              attrs: { title: node.label },
                                            },
                                            [_vm._v(_vm._s(node.label))]
                                          ),
                                        ])
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.accomodation.department,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.accomodation,
                                        "department",
                                        $$v
                                      )
                                    },
                                    expression: "accomodation.department",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.FormMSG(11, "Description"),
                                },
                              },
                              [
                                _c("b-form-input", {
                                  class: {
                                    "is-invalid":
                                      _vm.isSubmitted &&
                                      _vm.$v.accomodation.description.$error,
                                  },
                                  model: {
                                    value: _vm.accomodation.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.accomodation,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "accomodation.description",
                                  },
                                }),
                                _vm.isSubmitted &&
                                _vm.$v.accomodation.description.$error
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                52,
                                                "Please, description is required"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.selectedUser.length === 0 && _vm.isSubmitted === true
                      ? _c(
                          "div",
                          { staticClass: "text-empty-person px-1 pb-3" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t* " +
                                _vm._s(
                                  _vm.FormMSG(
                                    51,
                                    "Please, select one or more person(s) for this booking."
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.selectedUser.length > 0
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  cols: `${_vm.$screen.width <= 576 ? 5 : 2}`,
                                },
                              },
                              [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("h6", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(12, "Person list"))
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "w-10 ml-2",
                                      style: `${
                                        _vm.$screen.width <= 576
                                          ? "margin-top: -4px"
                                          : "margin-top: -1px"
                                      }`,
                                    },
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "badge-variant",
                                          attrs: { pill: "" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "badge-notification",
                                              staticStyle: {
                                                "font-size": "0.785rem",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedUser.length)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  cols: `${_vm.$screen.width <= 576 ? 3 : 8}`,
                                },
                              },
                              [_c("div", { staticClass: "divider-dotted" })]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "d-flex justify-content-end",
                                attrs: {
                                  cols: `${_vm.$screen.width <= 576 ? 4 : 2}`,
                                },
                              },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "d-flex align-items-center cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearAllSelectedUser()
                                      },
                                    },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("X"), {
                                      tag: "component",
                                      staticClass: "mr-1",
                                      attrs: {
                                        color: "#EA4E2C",
                                        size: 18,
                                        "stroke-width": 3,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "2px" } },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(13, "Clear All"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedUser.length > 0
                      ? _c(
                          "b-row",
                          {
                            staticClass: "px-3 mt-2",
                            class: `${
                              _vm.selectedUser.length > 0 ? "mb-2" : ""
                            }`,
                          },
                          _vm._l(_vm.selectedUser, function (user) {
                            return _c(
                              "b-col",
                              {
                                key: user.value,
                                staticClass: "d-flex mb-2",
                                attrs: { sm: "2" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-flex justify-content-center align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeUser(user.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("Trash"), {
                                      tag: "component",
                                      staticClass: "mr-2 cursor-pointer",
                                      attrs: {
                                        color: "#EA4E2C",
                                        size: 20,
                                        id: `tooltip-del-${user.id}`,
                                      },
                                    }),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: `tooltip-del-${user.id}`,
                                          triggers: "hover",
                                          placement: "left",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(47, "Delete"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.top",
                                            modifiers: {
                                              hover: true,
                                              top: true,
                                            },
                                          },
                                        ],
                                        staticClass: "text-truncate",
                                        staticStyle: { "margin-top": "2px" },
                                        style: `${
                                          _vm.$screen.width <= 576
                                            ? "max-width: 270px;"
                                            : "max-width: 140px;"
                                        }`,
                                        attrs: { title: user.fullName },
                                      },
                                      [
                                        _c("b", [
                                          _vm._v(_vm._s(user.fullName)),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text-truncate",
                                            staticStyle: {
                                              "font-size": "0.85rem",
                                              color: "rgba(6, 38, 62, 0.64)",
                                              "margin-top": "-1px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(user.departmentName) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(4, "Booking")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            attrs: {
                              sm: `${_vm.accomodation.category === 1 ? 6 : 12}`,
                              md: `${_vm.accomodation.category === 1 ? 6 : 12}`,
                              lg: `${_vm.accomodation.category === 1 ? 3 : 4}`,
                              xl: `${_vm.accomodation.category === 1 ? 3 : 4}`,
                            },
                          },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(14, "Category") } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    options: _vm.categoryOptions,
                                    label: "text",
                                    reduce: (option) => option.value,
                                    placeholder: _vm.FormMSG(
                                      15,
                                      "Select a category"
                                    ),
                                    clearable: false,
                                  },
                                  on: { input: _vm.categoryChanged },
                                  model: {
                                    value: _vm.accomodation.category,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.accomodation,
                                        "category",
                                        $$v
                                      )
                                    },
                                    expression: "accomodation.category",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.accomodation.category === 1
                          ? _c(
                              "b-col",
                              [
                                _c("transport-type-selector", {
                                  ref: "transportTypeSelectorAccomodation",
                                  attrs: {
                                    "hide-freight-type": "",
                                    "data-type": _vm.accomodation.type,
                                    "data-detail": _vm.accomodation.coTwoType,
                                    "data-additional-detail":
                                      _vm.accomodation.bookingStatus,
                                    "hide-special-vehicles": "",
                                  },
                                  on: {
                                    "transportation-type-selector:change":
                                      _vm.handleTransportationTypeSelectorChange,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.accomodation.category !== 1
                          ? _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "4", xl: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(16, "Type") } },
                                  [
                                    _c("v-select", {
                                      class: {
                                        "is-invalid":
                                          _vm.isSubmitted &&
                                          _vm.$v.accomodation.type.$error,
                                      },
                                      attrs: {
                                        options: _vm.accomodationTypeOptions,
                                        label: "text",
                                        reduce: (option) => option.value,
                                        placeholder: _vm.FormMSG(
                                          17,
                                          "Select a type"
                                        ),
                                        clearable: false,
                                        selectable: _vm.selectableTypeItem,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "option",
                                            fn: function (option) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    class: `${
                                                      (option.disabled &&
                                                        option.disabled ===
                                                          true) ||
                                                      (option.selectable &&
                                                        option.selectable ===
                                                          true)
                                                        ? "text-bold"
                                                        : "text-color ml-3"
                                                    }`,
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(option.text) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        80711263
                                      ),
                                      model: {
                                        value: _vm.accomodation.type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.accomodation,
                                            "type",
                                            $$v
                                          )
                                        },
                                        expression: "accomodation.type",
                                      },
                                    }),
                                    _vm.isSubmitted &&
                                    !_vm.$v.accomodation.type.required
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    53,
                                                    "Please, select a type"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.accomodation.category !== 1
                          ? _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "4", xl: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(44, "No. of bedrooms"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.isSubmitted &&
                                          _vm.$v.accomodation.nbBedroom.$error,
                                      },
                                      attrs: {
                                        type: "number",
                                        step: "1",
                                        min: "0",
                                        placeholder: "0",
                                      },
                                      model: {
                                        value: _vm.accomodation.nbBedroom,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.accomodation,
                                            "nbBedroom",
                                            $$v
                                          )
                                        },
                                        expression: "accomodation.nbBedroom",
                                      },
                                    }),
                                    _vm.isSubmitted &&
                                    _vm.$v.accomodation.nbBedroom.$error
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    55,
                                                    "Please, value must be greater than 0"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "6", md: "6", lg: "3", xl: "3" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { label: _vm.FormMSG(19, "Start date") },
                              },
                              [
                                _c("v-date-picker", {
                                  attrs: {
                                    "available-dates": {
                                      start: _vm.moment().format("YYYY-MM-DD"),
                                      end: null,
                                    },
                                    locale: _vm.lang,
                                    masks: _vm.masks,
                                  },
                                  on: { dayclick: _vm.handleStartDate },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({
                                        inputValue,
                                        togglePopover,
                                      }) {
                                        return [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                staticClass: "br-none",
                                                attrs: {
                                                  value: inputValue,
                                                  readonly: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer bg-color-white input-group-text-bg-white",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return togglePopover()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Calendar"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              "rgba(6, 38, 62, .6)",
                                                            size: 18,
                                                            "stroke-width": 2.25,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.accomodation.startDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.accomodation,
                                        "startDate",
                                        $$v
                                      )
                                    },
                                    expression: "accomodation.startDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "6", md: "6", lg: "3", xl: "3" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { label: _vm.FormMSG(20, "Start time") },
                              },
                              [
                                _c("input-date-time-picker", {
                                  attrs: {
                                    uiid: "accst",
                                    type: "text",
                                    placeholder: "hh:mm",
                                    item: _vm.accomodation.startTime,
                                    nbHour: _vm.rangeHour[_vm.WORKTYPE.daily],
                                    nbMinute:
                                      _vm.rangeMinute[_vm.WORKTYPE.daily],
                                    isEditable: () => {
                                      return true
                                    },
                                    fieldName: "startTime",
                                    workType: _vm.WORKTYPE.daily,
                                    iconColor: "rgba(6, 38, 62, 0.6)",
                                    iconStrokeWidth: 2.25,
                                    iconSize: 18,
                                    iconBg:
                                      "cursor-pointer bg-color-white input-group-text-bg-white",
                                  },
                                  on: { change: _vm.handleChangeHourMin },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "6", md: "6", lg: "3", xl: "3" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(21, "End date") } },
                              [
                                _c("v-date-picker", {
                                  attrs: {
                                    "available-dates": {
                                      start: _vm.moment().format("YYYY-MM-DD"),
                                      end: null,
                                    },
                                    locale: _vm.lang,
                                    masks: _vm.masks,
                                  },
                                  on: { dayclick: _vm.handleEndDate },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({
                                        inputValue,
                                        togglePopover,
                                      }) {
                                        return [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                staticClass: "br-none",
                                                attrs: {
                                                  value: inputValue,
                                                  readonly: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer bg-color-white input-group-text-bg-white",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return togglePopover()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Calendar"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              "rgba(6, 38, 62, .6)",
                                                            size: 18,
                                                            "stroke-width": 2.25,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.accomodation.endDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.accomodation, "endDate", $$v)
                                    },
                                    expression: "accomodation.endDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "6", md: "6", lg: "3", xl: "3" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(22, "End time") } },
                              [
                                _c("input-date-time-picker", {
                                  attrs: {
                                    uiid: "accet",
                                    type: "text",
                                    placeholder: "hh:mm",
                                    item: _vm.accomodation.endTime,
                                    nbHour: _vm.rangeHour[_vm.WORKTYPE.daily],
                                    nbMinute:
                                      _vm.rangeMinute[_vm.WORKTYPE.daily],
                                    isEditable: () => {
                                      return true
                                    },
                                    fieldName: "endTime",
                                    workType: _vm.WORKTYPE.daily,
                                    iconColor: "rgba(6, 38, 62, 0.6)",
                                    iconStrokeWidth: 2.25,
                                    iconSize: 18,
                                    isSubmitted: _vm.isSubmitted,
                                    error:
                                      !_vm.$v.accomodation.endTime
                                        .hourMinuteValid,
                                    iconBg:
                                      "cursor-pointer bg-color-white input-group-text-bg-white",
                                  },
                                  on: { change: _vm.handleChangeHourMin },
                                }),
                                _vm.isSubmitted &&
                                !_vm.$v.accomodation.endTime.hourMinuteValid
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                60,
                                                "End time must be greater than start time"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "pb-2" },
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                          [
                            _c("supplier-selector", {
                              attrs: {
                                title: "Supplier",
                                label: _vm.FormMSG(23, "Supplier"),
                                placeholder: _vm.FormMSG(
                                  24,
                                  "Type to search ..."
                                ),
                                addlabel: _vm.FormMSG(25, "Add"),
                                "update-label": _vm.FormMSG(26, "Update"),
                                "supplier-id": _vm.accomodation.supplierId,
                                "show-map": false,
                                type: "hotel",
                                "use-new-design": "",
                                version: "1.0",
                              },
                              on: {
                                "supplier:unselected": _vm.onUnselected,
                                change: _vm.handleSupplierChanged,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            class: `${_vm.$screen.width <= 576 ? "mt-3" : ""}`,
                            attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { label: _vm.FormMSG(62, "Attachment") },
                              },
                              [
                                _c("b-form-file", {
                                  ref: "accomodationFile",
                                  staticClass: "mb-2",
                                  attrs: {
                                    accept: _vm.acceptFiles,
                                    placeholder: _vm.FormMSG(
                                      63,
                                      "Choose a file or drop it here ..."
                                    ),
                                    "browse-text": _vm.FormMSG(64, "Browse"),
                                    "drop-placeholder": _vm.FormMSG(
                                      65,
                                      "Drop file here ..."
                                    ),
                                  },
                                  on: {
                                    change: _vm.handleChangeFileToUploaded,
                                  },
                                  model: {
                                    value: _vm.accomodation.fileToUploaded,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.accomodation,
                                        "fileToUploaded",
                                        $$v
                                      )
                                    },
                                    expression: "accomodation.fileToUploaded",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.accomodation.category === 1
                                ? _vm.FormMSG(5, "Route")
                                : _vm.FormMSG(43, "Place")
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm.accomodation.category === 1
                      ? _c(
                          "div",
                          [
                            _c("google-distance", {
                              attrs: {
                                "edit-data": _vm.accomodation,
                                "from-location-label": _vm.FormMSG(
                                  28,
                                  "From address"
                                ),
                                "to-location-label": _vm.FormMSG(
                                  27,
                                  "To address"
                                ),
                                "field-from": "fromAddress",
                                "field-to": "toAddress",
                                "field-total-distance": "totalDistance",
                                "to-location-error-message": _vm.FormMSG(
                                  56,
                                  "Please, arrival point is required"
                                ),
                                "from-location-error-message": _vm.FormMSG(
                                  57,
                                  "Please, starting point is required"
                                ),
                                "inline-validator": true,
                                "is-submitted": _vm.isSubmitted,
                              },
                              on: {
                                change: _vm.handleDistanceChanged,
                                "google-distance:loading": _vm.onGoogleLoading,
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        45,
                                        "Accommodation name"
                                      ),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.isSubmitted &&
                                          _vm.$v.accomodation.name.$error,
                                      },
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.accomodation.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.accomodation,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "accomodation.name",
                                      },
                                    }),
                                    _vm.isSubmitted &&
                                    _vm.$v.accomodation.name.$error
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    58,
                                                    "Please, accommodation name is required"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(46, "Address"),
                                    },
                                  },
                                  [
                                    _c("v-address", {
                                      attrs: {
                                        params: {
                                          title: _vm.FormMSG(
                                            48,
                                            "Accomodation Address"
                                          ),
                                        },
                                        "address-id":
                                          _vm.accomodation.addressId,
                                        "is-submitted": _vm.isSubmitted,
                                        error:
                                          _vm.$v.accomodation.addressId.$error,
                                      },
                                      on: {
                                        change: _vm.handleDynamicAddressChange,
                                        delete: _vm.handleDynamicAddressDelete,
                                        "address-maplink-change":
                                          _vm.handleAddressMapLinkChange,
                                      },
                                    }),
                                    _vm.isSubmitted &&
                                    _vm.$v.accomodation.addressId.$error
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    61,
                                                    "Please, enter an address valid"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.accomodation.category === 1
                          ? _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "3", xl: "3" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(29, "Total distance"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "is-invalid":
                                              _vm.isSubmitted &&
                                              _vm.$v.accomodation.totalDistance
                                                .$error,
                                          },
                                          attrs: {
                                            type: "number",
                                            min: "0",
                                            step: "0.001",
                                            placeholder: "0",
                                            readonly: _vm.readonlyTotalDistance,
                                          },
                                          model: {
                                            value:
                                              _vm.accomodation.totalDistance,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.accomodation,
                                                "totalDistance",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "accomodation.totalDistance",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticStyle: {
                                                  background: "#47c7bf",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                    staticStyle: {
                                                      color: "#fff",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.distanceUnit)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isSubmitted &&
                                    _vm.$v.accomodation.totalDistance.$error
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    55,
                                                    "Please, value must be greater than 0"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          {
                            attrs: {
                              sm: "12",
                              md: "12",
                              lg: `${_vm.accomodation.category === 1 ? 9 : 12}`,
                              xl: `${_vm.accomodation.category === 1 ? 9 : 12}`,
                            },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.FormMSG(
                                    30,
                                    "Special instructions"
                                  ),
                                },
                              },
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.accomodation.specialInstruction,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.accomodation,
                                        "specialInstruction",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "accomodation.specialInstruction",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                    staticStyle: {
                      "background-color": "rgba(248, 249, 251, 0.5)",
                    },
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(6, "Communication")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex justify-content-start align-items-center",
                            attrs: { sm: "5", md: "5", lg: "3", xl: "3" },
                          },
                          [
                            _c("h6", [
                              _c("b", [
                                _vm._v(_vm._s(_vm.FormMSG(31, "Send type"))),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "2", md: "2", lg: "6", xl: "6" } },
                          [
                            _c("div", {
                              staticClass: "divider-dotted",
                              style: `${
                                _vm.$screen.width > 576
                                  ? "margin: 12px 0 0 0"
                                  : "margin: 4px 0 8px 0"
                              }`,
                            }),
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex justify-content-end align-items-start",
                            attrs: { sm: "4", md: "5", lg: "3", xl: "3" },
                          },
                          [
                            _vm.currentAccommodation &&
                            _vm.currentAccommodation.publishedOn ===
                              "0001-01-01T00:00:00Z"
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "w-138-px",
                                    attrs: {
                                      size: "md",
                                      variant: "outline-secondary",
                                      block: "",
                                    },
                                    on: { click: _vm.publishAccommodation },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center align-items-center",
                                      },
                                      [
                                        _c("b-spinner", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.watingPublishDelivery,
                                              expression:
                                                "watingPublishDelivery",
                                            },
                                          ],
                                          attrs: { small: "" },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            class: `${
                                              _vm.watingPublishDelivery
                                                ? "pl-2"
                                                : ""
                                            }`,
                                            staticStyle: {
                                              "margin-top": "1px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    32,
                                                    "Send details"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      {
                        staticClass: "d-flex justify-content-center",
                        class: `${_vm.action === "ADD" ? "pb-2" : ""}`,
                      },
                      [
                        _c(
                          "b-col",
                          {
                            attrs: {
                              sm: "12",
                              md: "12",
                              lg: "8",
                              xl: "6",
                              "offset-sm": "0",
                            },
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    class: `${
                                      _vm.$screen.width > 576
                                        ? "d-flex justify-content-center"
                                        : "d-flex justify-content-start"
                                    }`,
                                    attrs: { sm: "4" },
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb pb-1",
                                        attrs: { size: "lg", id: "toEmail" },
                                        on: { change: _vm.onChangeEmail },
                                        model: {
                                          value: _vm.accomodation.toEmail,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.accomodation,
                                              "toEmail",
                                              $$v
                                            )
                                          },
                                          expression: "accomodation.toEmail",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(33, "Email")) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    class: `${
                                      _vm.$screen.width > 576
                                        ? "d-flex justify-content-center"
                                        : "d-flex justify-content-start"
                                    }`,
                                    attrs: { sm: "4" },
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb pb-1",
                                        attrs: { size: "lg", id: "toSms" },
                                        on: { change: _vm.onChangeSms },
                                        model: {
                                          value: _vm.accomodation.toSms,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.accomodation,
                                              "toSms",
                                              $$v
                                            )
                                          },
                                          expression: "accomodation.toSms",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(34, "Text message")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    class: `${
                                      _vm.$screen.width > 576
                                        ? "d-flex justify-content-center"
                                        : "d-flex justify-content-start"
                                    }`,
                                    attrs: { sm: "4" },
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb pb-1",
                                        attrs: { size: "lg", id: "toWhatsApp" },
                                        on: { change: _vm.onChangeWhatsApp },
                                        model: {
                                          value: _vm.accomodation.toWhatsApp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.accomodation,
                                              "toWhatsApp",
                                              $$v
                                            )
                                          },
                                          expression: "accomodation.toWhatsApp",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(35, "WhatsApp")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.unknownDevice
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "invalid-feedback text-center pb-2",
                                    staticStyle: { "margin-top": "-2px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t* " +
                                        _vm._s(
                                          _vm.FormMSG(
                                            69,
                                            "Please, select one or more device(s) to send the details."
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center mt-2",
                  style: `display: ${
                    _vm.displayAddBtn ? "block" : "none"
                  } !important;`,
                  attrs: {
                    sm: "4",
                    md: "4",
                    lg: "4",
                    xl: "4",
                    "offset-sm": "5",
                    "offset-md": "5",
                    "offset-lg": "5",
                    "offset-xl": "5",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      ref: "submit",
                      staticClass: "w-138-px",
                      attrs: {
                        type: "submit",
                        size: "md",
                        variant: "outline-primary",
                        block: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(59, "Save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("accomodation-success", {
        on: { "accomodation-success-modal:closed": _vm.onSuccessClosed },
        model: {
          value: _vm.isAccomodationAdded,
          callback: function ($$v) {
            _vm.isAccomodationAdded = $$v
          },
          expression: "isAccomodationAdded",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }