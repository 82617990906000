<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="title"
		size="xl"
		centered
		cancel-variant="light"
		@show="resetModal"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<div class="container-layout">
			<accomodation-form
				ref="form"
				:action="action"
				:enable-btn-report="false"
				:display-add-btn="false"
				@accomodation-form:added="onAccomodationAdded"
				@accomodation-form:waiting="onAccomodationLoaded"
				@accomodation-form:close-modal="onAccomodationForceClosed"
				@accomodation-form:hide-submit="onAccomodationSubmitButtonHidden"
				@accomodation-form:published="onAccomodationPublished"
			/>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="md"
					variant="custom-outline-gray"
					style="margin-top: 5px"
					class="w-138-px mr-3"
					:disabled="watingSubmitForm"
					@click="emitEventClose"
					block
				>
					{{ !btnCloseOnly ? FormMSG(1, 'Cancel') : FormMSG(3, 'Close') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="watingSubmitForm" @click="dispatchEvent" block v-show="!btnCloseOnly">
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import AccomodationForm from '@/components/Accomodation/Form';
import _ from 'lodash';

export default {
	name: 'AccomodationFormModal',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: true,
			default: ''
		},
		action: {
			type: String,
			required: true,
			default: ''
		}
	},
	components: {
		AccomodationForm
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	data() {
		return {
			watingSubmitForm: false,
			btnCloseOnly: false
		};
	},
	methods: {
		emitEventClose() {
			this.$emit('accomodation-form-modal:closed');
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['form'].$refs['submit'].click();
		},
		onAccomodationAdded(payload) {
			this.$emit('accomodation-form-modal:new-accomodation-delivery-list', payload);
		},
		onAccomodationLoaded(payload) {
			this.watingSubmitForm = payload;
		},
		onAccomodationForceClosed(payload) {
			if (!_.isNil(payload) && payload === true) {
				this.$emit('accomodation-form-modal:closed');
			}
		},
		onAccomodationSubmitButtonHidden(payload) {
			if (!_.isNil(payload) && payload === true) {
				this.btnCloseOnly = true;
			}
		},
		resetModal() {
			this.btnCloseOnly = false;
		},
		onAccomodationPublished(payload) {
			this.$emit('accomodation-form-modal:all-accomodation-delivery', payload);
		}
	}
};
</script>
