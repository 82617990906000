var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: _vm.selectorRef, attrs: { id: _vm.selectorRef } },
    [
      _vm.label
        ? _c(
            "label",
            { staticClass: "d-block", attrs: { for: "select-component" } },
            [_vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t")]
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex" },
            [
              _c("v-select", {
                staticClass: "grow-flex-auto",
                attrs: {
                  id: `${_vm.iconActions.length > 0 ? "custom-select" : ""}`,
                  placeholder: _vm.rendPlaceholder,
                  options: _vm.options,
                  label: _vm.labelField,
                  reduce: (option) => option[_vm.keyField],
                  disabled: _vm.disabled,
                },
                on: { input: _vm.handleChange, search: _vm.handleSearch },
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-row",
                  class: {
                    iconGroupBorderAppend: `${_vm.iconActions.length > 0}`,
                  },
                },
                [
                  _vm._l(_vm.iconActions, function (data) {
                    return [
                      _c(
                        "template",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              modifiers: { hover: true, top: true },
                            },
                          ],
                          staticClass:
                            "d-inline-flex justify-content-center align-items-center",
                          style: `width: ${_vm.defaultIconWidth}`,
                          attrs: { title: data.title },
                          on: { click: data.function },
                          slot: data.key,
                        },
                        [_vm._t(data.key, null, { item: data.item })],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }