<style scoped>
body {
	overflow-x: unset !important;
}
</style>
<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'" class="px-0">
				<b-row class="form">
					<b-col cols="12">
						<transition name="slide">
							<b-card no-body class="card-border-blue-light px-4 pt-3 pb-2">
								<b-row class="d-flex align-items-center">
									<b-col :cols="`${$screen.width >= 992 ? 6 : 0}`" class="my-1">
										<b-input-group v-if="$screen.width >= 992">
											<b-form-input v-model="filter" type="text" :placeholder="FormMSG(20, 'Type to Search')" />
											<b-input-group-append class="cursor-pointer">
												<b-input-group-text class="btn-search">
													<component
														:is="getLucideIcon('Search')"
														color="#FFFFFF"
														:size="16"
														class="icon"
														:stroke-width="2.5"
														v-if="filter.length === 0"
													/>
													<component
														:is="getLucideIcon('X')"
														color="#FFFFFF"
														:size="16"
														class="icon"
														:stroke-width="2.5"
														@click="filter = ''"
														v-else
													/>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-col>
									<b-col :cols="`${$screen.width >= 992 ? 6 : 12}`" class="d-flex justify-content-end">
										<b-button
											:block="!$screen.md"
											@click="newAccomodation()"
											variant="primary"
											size="md"
											class="d-flex justify-content-center align-items-center"
										>
											<component :is="getLucideIcon('Plus')" :size="18" color="#FFFFFF" :stroke-width="2.5" style="margin-top: -2px" />
											<div class="px-2">{{ FormMSG(51, 'New Booking') }}</div>
										</b-button>
									</b-col>
								</b-row>
								<b-row class="mt-1">
									<b-col cols="12" xl="12">
										<card-list-builder
											v-if="$screen.width < 992"
											:use-new-version="true"
											:has-badge="true"
											:is-badge-use-props="true"
											:hide-status="false"
											:toggle-mode="true"
											:items="accommodationDeliveries"
											:fields="omit(accEntryFields, ['selected', 'status', 'edit'])"
											badge-class="statusClass"
											badge-value="statusText"
										>
											<template slot="view" slot-scope="data">
												<div class="pr-0" @click="handlePreviewDocument(data.item)" v-if="data.item.fileName.length > 0">
													<img
														:src="`svgs/bootstrap/filetype-${getExtensionFile(data.item.fileName)}.svg`"
														width="24"
														height="24"
														:id="generateTextId(`view-${data.item.id}`)"
													/>
													<b-tooltip :target="generateTextId(`view-${data.item.id}`)" placement="left">
														{{ data.item.fileName }}
													</b-tooltip>
												</div>
											</template>
											<template slot="actions" slot-scope="data">
												<b-button
													variant="primary"
													size="sm"
													@click="editAccomodation(data.item, data.index)"
													class="btn bg-transparent border-0"
												>
													<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
												</b-button>
												<b-button variant="danger" size="sm" @click="delItem(data.item)" class="ml-2 btn bg-transparent border-0">
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</card-list-builder>
										<b-table
											v-if="$screen.width >= 992"
											:hover="hover"
											sticky-header="547px"
											responsive="sm"
											ref="myTable"
											selectable
											:selectedVariant="selectedColor"
											:select-mode="selectMode"
											:items="accommodationDeliveries"
											style="text-align: left"
											:fields="accEntryFields"
											:filter="filter"
											bordered
											striped
											small
											:head-variant="hv"
											:empty-text="FormMSG(65, 'No accommodation found')"
											show-empty
											:busy="isTableBusy"
											outlined
											@row-dblclicked="editAccomodation"
										>
											<template #head(selected)="">
												<div class="text-center w-100 p-1" style="margin-left: 3px" id="delete-all">
													<b-form-checkbox v-model="selectAll" size="md" @change="handleInputSelectAll" />
												</div>
											</template>
											<template #cell(selected)="data">
												<div class="text-center w-100 p-1" style="margin-left: 3px">
													<b-form-checkbox
														v-model="data.item.selected"
														size="md"
														@change="handleInputItemChecked($event, data.item)"
													/>
												</div>
											</template>
											<template #cell(status)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.statusText }}
													</div>
												</div>
											</template>
											<template #cell(view)="data">
												<div
													@click="handlePreviewDocument(data.item)"
													class="d-flex justify-content-center align-items-center px-1"
													v-if="data.item.fileName.length > 0"
												>
													<img
														:src="`${
															getExtensionFile(data.item.fileName) === 'zip' || getExtensionFile(data.item.fileName) === 'rar'
																? 'svgs/bootstrap/file-zip.svg'
																: 'svgs/bootstrap/filetype-' + getExtensionFile(data.item.fileName) + '.svg'
														}`"
														width="26"
														height="26"
														:id="generateTextId(`view-${data.item.id}`)"
													/>
													<b-tooltip :target="generateTextId(`view-${data.item.id}`)" placement="left">
														{{ data.item.fileName }}
													</b-tooltip>
												</div>
											</template>
											<template #cell(edit)="data">
												<b-button
													variant="primary"
													size="sm"
													@click="editAccomodation(data.item, data.index)"
													class="btn bg-transparent border-0"
												>
													<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
												</b-button>
											</template>
											<template #table-busy>
												<div class="text-center text-success my-2">
													<b-spinner class="align-middle"></b-spinner>
												</div>
											</template>
										</b-table>
									</b-col>
								</b-row>
								<b-row v-if="$screen.width >= 992" class="pb-2">
									<b-col>
										<b-button
											size="md"
											variant="custom-outline-gray"
											class="pl-5 pr-5"
											:disabled="accomodationIdsToDelete.length === 0"
											@click="handleDeleteAccomodation"
										>
											{{ FormMSG(62, 'Delete') }}
										</b-button>
									</b-col>
								</b-row>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="this.FormMSG(104, 'Success!')"
									class="modal-success"
									v-model="successModal"
									@ok="successModal = false"
									ok-variant="success"
									ok-only
								>
									{{ FormMSG(12121213, 'The new accomodation has been saved.') }}
								</b-modal>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="this.FormMSG(104, 'Success!')"
									class="modal-success"
									v-model="successModalDelete"
									@ok="successModalDelete = false"
									ok-variant="success"
									ok-only
								>
									{{ FormMSG(12121212, 'This accomodation has been deleted.') }}
								</b-modal>
								<form-modal
									v-model="isAccomodationFormOpen"
									:title="titleFormModal"
									:action="action"
									@accomodation-form-modal:closed="onFormModalCancelled"
									@accomodation-form-modal:new-accomodation-delivery-list="onAccomodationDeliveryAdded"
									@accomodation-form-modal:all-accomodation-delivery="onAccomodationDeliveryRefreshed"
								/>
								<update-modal
									v-model="isAccomodationUpdateFormOpen"
									:item="item"
									:action="action"
									@accomodation-update-form-modal:closed="onUpdateFormModalCancelled"
									@accomodation-update-form-modal:reload="onAccomodationDeliveryRefreshed"
								/>
							</b-card>
						</transition>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { store } from '@/store';
import moment from 'moment';
import { generateSecureId } from '@/shared/utils';
import SupplierSelector from '@/components/SupplierSelector';
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import FormModal from '@/components/Accomodation/FormModal';
import { getAccomodationDeliveries, removeAccomodationDeliveries } from '@/cruds/accomodation.crud';
import { classDocumentStatus } from '@/shared/global-status';
import { recalculateDistanceValueByUnit } from '@/shared/google/helpers';
import UpdateModal from '@/components/Accomodation/UpdateModal';
import _ from 'lodash';

export default {
	name: 'AccomodationList',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		SupplierSelector,
		FormModal,
		UpdateModal
	},
	data: () => {
		//const tt = this.FormMSG;
		return {
			hv: 'dark',
			erreur: {},
			currentPage: 1,
			perPage: 0,
			warning: '',
			filter: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			editData: {
				type: Object,
				required: false,
				default: null
			},
			selectAll: false,
			isAccomodationFormOpen: false,
			titleFormModal: '',
			action: '',
			item: {},
			accommodationDeliveries: [],
			isTableBusy: false,
			accomodationIdsToDelete: [],
			isAccomodationUpdateFormOpen: false
			//  bob : tt(10,"testbob"),
		};
	},
	computed: {
		accEntryFields() {
			return [
				{
					key: 'selected',
					label: '',
					thStyle: { width: '2.5%' },
					class: 'text-center',
					sortable: false
				},
				{
					key: 'acc.reference',
					label: 'N°',
					class: 'text-center',
					sortable: true
				},
				{
					key: 'startDate',
					label: this.FormMSG(52, 'Date start'),
					class: 'text-center',
					sortable: true,
					formatter: (value, key, item) => {
						if (_.has(item, 'startTime')) {
							return moment(value).utc().format('DD/MM/YYYY') + ' ' + item.startTime;
						}

						return moment(value).utc().format('DD/MM/YYYY HH:mm');
					}
				},
				{
					key: 'endDate',
					label: this.FormMSG(53, 'Date end'),
					class: 'text-center',
					sortable: true,
					formatter: (value, key, item) => {
						if (_.has(item, 'endTime')) {
							return moment(value).utc().format('DD/MM/YYYY') + ' ' + item.endTime;
						}

						return moment(value).utc().format('DD/MM/YYYY HH:mm');
					}
				},
				{
					key: 'fullName',
					label: this.FormMSG(64, 'Person'),

					sortable: true,
          sortByFormatted: true,
					formatter: (value, key, item) => {
            return item.user.name + ' ' + item.user.firstName;
					},
					class: 'text-center'
				},
				{
					key: 'user.departmentName',
					label: this.FormMSG(54, 'Department'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'type',
					label: this.FormMSG(55, 'Type'),
					class: 'text-center',
					sortable: true,
          sortByFormatted: true,
					formatter: (value, key, item) => {
						const travels = this.travelTypeOptions;
						const accommodations = this.accommodationTypeOptions;
						let match = [];
						if (+item.acc.category === 0) {
							// accommodation
							match = accommodations.filter((accommodation) => accommodation.value === +item.acc.type);
							if (match.length > 0) {
								return match[0].text;
							}
						} else if (+item.acc.category === 1) {
							// travel
							match = travels.filter((travel) => travel.value === +item.acc.type);
							if (match.length > 0) {
								return match[0].text;
							}
						}

						return '';
					}
				},
				{
					key: 'acc.description',
					label: this.FormMSG(56, 'Description'),
					sortable: false
				},
				{
					key: 'acc.supplier.name',
					label: this.FormMSG(57, 'Supplier'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'numberOfNights',
					label: this.FormMSG(59, 'Nights'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'acc.totalDistance',
					label: this.distanceUnit,
					sortable: true,
					class: 'text-center',
					formatter: (value) => {
						return recalculateDistanceValueByUnit(value);
					}
				},
				{
					key: 'status',
					label: this.FormMSG(60, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'view',
					label: this.FormMSG(61, 'View'),
					sortable: false,
					class: 'text-center',
					isSlot: true
				},
				{
					key: 'edit',
					label: this.FormMSG(78, 'Edit'),
					sortable: false,
					class: 'text-center'
				}
			];
		},
		caption1() {
			return this.FormMSG(8, 'Accomodations');
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		travelTypeOptions() {
			// value 1
			const boats = this.FormMenu(1404);
			const bus = this.FormMenu(1405);
			const cars = this.FormMenu(1406);
			const others = this.FormMenu(1407);

			return [...boats, ...bus, ...cars, ...others];
		},
		accommodationTypeOptions() {
			// value 0
			const hotels = this.FormMenu(1415);
			const houses = this.FormMenu(1417);
			const apparts = this.FormMenu(1416);

			return [...hotels, ...houses, ...apparts];
		}
	},
	methods: {
		editAccomodation(item, index) {
			this.action = 'UPDATE';
			this.isAccomodationUpdateFormOpen = true;
			this.item = item;
			if (!_.isNil(index)) {
				this.item.index = index;
			}
		},
		async reloadData() {
			this.isTableBusy = true;
			this.accommodationDeliveries = await getAccomodationDeliveries();
			// console.log({ accommodationDeliveries: this.accommodationDeliveries });

			for (let i = 0; i < this.accommodationDeliveries.length; i++) {
				this.accommodationDeliveries[i].statusText = this.statusText(this.accommodationDeliveries[i].status);
				this.accommodationDeliveries[i].statusClass = classDocumentStatus(this.accommodationDeliveries[i].status);
			}
			this.isTableBusy = false;
		},
		statusText(value) {
			if (value === 0) {
				return this.FormMSG(66, 'Not published');
			}

			if (value === 1) {
				return this.FormMSG(67, 'Not received');
			}

			if (value === 2) {
				return this.FormMSG(68, 'Received (not read)');
			}

			if (value === 3) {
				return this.FormMSG(69, 'Read');
			}

			if (value === 4) {
				return this.FormMSG(70, 'Signed');
			}
		},
		delItem(item) {
			const action = async () => {
				let ids = [
					{
						id: +item.id,
						item: {
							accommodationId: +item.acc.id,
							userId: +item.user.id
						}
					}
				];
				// console.log({ ids });
				await removeAccomodationDeliveries(ids)
					.then((result) => {
						if (result) {
							this.createToastForMobile(this.FormMSG(76, 'Success'), this.FormMSG(77, 'Accommodation(s) removed successfully'), '', 'success');
						}
					})
					.catch((e) => {
						this.createToastForMobile(
							this.FormMSG(74, 'Error'),
							this.FormMSG(75, 'An error was occured during removed accommodation(s), please contact your administrator.'),
							'',
							'danger'
						);
					})
					.finally(async () => {
						await this.reloadData();
					});
			};
			this.confirmModal(action, this.FormMSG(73, 'Are you sure to remove selected accommodation(s) ?'), 'danger');
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		async newAccomodation() {
			this.titleFormModal = this.FormMSG(63, 'New Booking');
			this.action = 'ADD';
			this.isAccomodationFormOpen = true;
		},
		onFormModalCancelled() {
			this.isAccomodationFormOpen = false;
		},
		async onUpdateFormModalCancelled(payload) {
			this.isAccomodationUpdateFormOpen = false;
			if (_.isObject(payload)) {
				await this.reloadData();
			}
		},
		handleInputItemChecked(payload, delivery) {
			const findIndex = this.accomodationIdsToDelete.findIndex((option) => option.id === +delivery.id);

			if (findIndex > -1) {
				this.accomodationIdsToDelete.splice(findIndex, 1);

				if (this.accomodationIdsToDelete.length === 0) {
					this.selectAll = false;
				}
			} else if (findIndex === -1) {
				this.accomodationIdsToDelete.push({
					id: +delivery.id,
					item: {
						accommodationId: +delivery.acc.id,
						userId: +delivery.user.id
					}
				});
				this.selectAll = true;
			}
		},
		handleInputSelectAll(value) {
			this.accomodationIdsToDelete = [];
			this.accommodationDeliveries = this.accommodationDeliveries.map((option) => {
				if (value) {
					this.accomodationIdsToDelete.push({
						id: +option.id,
						item: {
							accommodationId: +option.acc.id,
							userId: +option.user.id
						}
					});
				}
				return {
					...option,
					selected: value
				};
			});
		},
		getExtensionFile(filename) {
			return filename.substring(filename.lastIndexOf('.') + 1);
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		handlePreviewDocument(item) {
			const fileToPreview = {
				...item,
				src: `${process.env.VUE_APP_GQL}/images/${item.confirmation}`,
				xid: item.confirmation,
				ext: this.getExtensionFile(item.fileName)
			};

			this.$previewImages({
				name: 'mydocumentdetails-previewer-elem',
				images: [fileToPreview],
				focusIndex: 0,
				options: {
					presentationMode: 'doc',
					// showStatusFileTag: true,
					hideCommentButton: false,
					hideDisLikeButton: false,
					hideLikeDislike: false,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: true
				}
			});
		},
		handleDeleteAccomodation() {
			const action = async () => {
				let ids = this.accomodationIdsToDelete.map((accomodationId) => {
					return accomodationId.item;
				});
				// console.log(ids);
				await removeAccomodationDeliveries(ids)
					.then((result) => {
						if (result) {
							this.createToastForMobile(this.FormMSG(76, 'Success'), this.FormMSG(77, 'Accommodation(s) removed successfully'), '', 'success');
						}
					})
					.catch((e) => {
						this.createToastForMobile(
							this.FormMSG(74, 'Error'),
							this.FormMSG(75, 'An error was occured during removed accommodation(s), please contact your administrator.'),
							'',
							'danger'
						);
					})
					.finally(async () => {
						await this.reloadData();
					});
			};
			const reset = async () => {
				this.selectAll = false;
				this.handleInputSelectAll(false);
			};
			this.confirmModal(action, this.FormMSG(73, 'Are you sure to remove selected accommodation(s) ?'), 'danger', reset);
		},
		async onAccomodationDeliveryAdded(payload) {
			await this.reloadData();
		},
		async onAccomodationDeliveryRefreshed(payload) {
			await this.reloadData();
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		omit(objects, keys) {
			return objects.filter((object) => !keys.includes(object.key));
		}
	},
	async created() {
		await this.reloadData();
	}
};
</script>
<style lang="scss">
#delete-all .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #fff;
	background-color: #00a09c;
}
</style>
