var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "px-0",
            class:
              _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
          },
          [
            _c(
              "b-row",
              { staticClass: "form" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "slide" } },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass:
                              "card-border-blue-light px-4 pt-3 pb-2",
                            attrs: { "no-body": "" },
                          },
                          [
                            _c(
                              "b-row",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "my-1",
                                    attrs: {
                                      cols: `${
                                        _vm.$screen.width >= 992 ? 6 : 0
                                      }`,
                                    },
                                  },
                                  [
                                    _vm.$screen.width >= 992
                                      ? _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  20,
                                                  "Type to Search"
                                                ),
                                              },
                                              model: {
                                                value: _vm.filter,
                                                callback: function ($$v) {
                                                  _vm.filter = $$v
                                                },
                                                expression: "filter",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              { staticClass: "cursor-pointer" },
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  { staticClass: "btn-search" },
                                                  [
                                                    _vm.filter.length === 0
                                                      ? _c(
                                                          _vm.getLucideIcon(
                                                            "Search"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            staticClass: "icon",
                                                            attrs: {
                                                              color: "#FFFFFF",
                                                              size: 16,
                                                              "stroke-width": 2.5,
                                                            },
                                                          }
                                                        )
                                                      : _c(
                                                          _vm.getLucideIcon(
                                                            "X"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            staticClass: "icon",
                                                            attrs: {
                                                              color: "#FFFFFF",
                                                              size: 16,
                                                              "stroke-width": 2.5,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.filter = ""
                                                              },
                                                            },
                                                          }
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "d-flex justify-content-end",
                                    attrs: {
                                      cols: `${
                                        _vm.$screen.width >= 992 ? 6 : 12
                                      }`,
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center align-items-center",
                                        attrs: {
                                          block: !_vm.$screen.md,
                                          variant: "primary",
                                          size: "md",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.newAccomodation()
                                          },
                                        },
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Plus"), {
                                          tag: "component",
                                          staticStyle: { "margin-top": "-2px" },
                                          attrs: {
                                            size: 18,
                                            color: "#FFFFFF",
                                            "stroke-width": 2.5,
                                          },
                                        }),
                                        _c("div", { staticClass: "px-2" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(51, "New Booking")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", xl: "12" } },
                                  [
                                    _vm.$screen.width < 992
                                      ? _c("card-list-builder", {
                                          attrs: {
                                            "use-new-version": true,
                                            "has-badge": true,
                                            "is-badge-use-props": true,
                                            "hide-status": false,
                                            "toggle-mode": true,
                                            items: _vm.accommodationDeliveries,
                                            fields: _vm.omit(
                                              _vm.accEntryFields,
                                              ["selected", "status", "edit"]
                                            ),
                                            "badge-class": "statusClass",
                                            "badge-value": "statusText",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "view",
                                                fn: function (data) {
                                                  return [
                                                    data.item.fileName.length >
                                                    0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "pr-0",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handlePreviewDocument(
                                                                  data.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: `svgs/bootstrap/filetype-${_vm.getExtensionFile(
                                                                  data.item
                                                                    .fileName
                                                                )}.svg`,
                                                                width: "24",
                                                                height: "24",
                                                                id: _vm.generateTextId(
                                                                  `view-${data.item.id}`
                                                                ),
                                                              },
                                                            }),
                                                            _c(
                                                              "b-tooltip",
                                                              {
                                                                attrs: {
                                                                  target:
                                                                    _vm.generateTextId(
                                                                      `view-${data.item.id}`
                                                                    ),
                                                                  placement:
                                                                    "left",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      data.item
                                                                        .fileName
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "actions",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "btn bg-transparent border-0",
                                                        attrs: {
                                                          variant: "primary",
                                                          size: "sm",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editAccomodation(
                                                              data.item,
                                                              data.index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.EDIT.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.EDIT
                                                                  .color,
                                                              size: 20,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "ml-2 btn bg-transparent border-0",
                                                        attrs: {
                                                          variant: "danger",
                                                          size: "sm",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delItem(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.TRASH.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.TRASH
                                                                  .color,
                                                              size: 20,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3416572392
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm.$screen.width >= 992
                                      ? _c("b-table", {
                                          ref: "myTable",
                                          staticStyle: { "text-align": "left" },
                                          attrs: {
                                            hover: _vm.hover,
                                            "sticky-header": "547px",
                                            responsive: "sm",
                                            selectable: "",
                                            selectedVariant: _vm.selectedColor,
                                            "select-mode": _vm.selectMode,
                                            items: _vm.accommodationDeliveries,
                                            fields: _vm.accEntryFields,
                                            filter: _vm.filter,
                                            bordered: "",
                                            striped: "",
                                            small: "",
                                            "head-variant": _vm.hv,
                                            "empty-text": _vm.FormMSG(
                                              65,
                                              "No accommodation found"
                                            ),
                                            "show-empty": "",
                                            busy: _vm.isTableBusy,
                                            outlined: "",
                                          },
                                          on: {
                                            "row-dblclicked":
                                              _vm.editAccomodation,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "head(selected)",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center w-100 p-1",
                                                        staticStyle: {
                                                          "margin-left": "3px",
                                                        },
                                                        attrs: {
                                                          id: "delete-all",
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-checkbox", {
                                                          attrs: { size: "md" },
                                                          on: {
                                                            change:
                                                              _vm.handleInputSelectAll,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectAll,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectAll =
                                                                $$v
                                                            },
                                                            expression:
                                                              "selectAll",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "cell(selected)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center w-100 p-1",
                                                        staticStyle: {
                                                          "margin-left": "3px",
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-checkbox", {
                                                          attrs: { size: "md" },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInputItemChecked(
                                                                $event,
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              data.item
                                                                .selected,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                data.item,
                                                                "selected",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "data.item.selected",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(status)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "wrap-status",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            class: `status ${data.item.statusClass}`,
                                                            staticStyle: {
                                                              "font-size":
                                                                "0.7rem",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  data.item
                                                                    .statusText
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(view)",
                                                fn: function (data) {
                                                  return [
                                                    data.item.fileName.length >
                                                    0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center px-1",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handlePreviewDocument(
                                                                  data.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: `${
                                                                  _vm.getExtensionFile(
                                                                    data.item
                                                                      .fileName
                                                                  ) === "zip" ||
                                                                  _vm.getExtensionFile(
                                                                    data.item
                                                                      .fileName
                                                                  ) === "rar"
                                                                    ? "svgs/bootstrap/file-zip.svg"
                                                                    : "svgs/bootstrap/filetype-" +
                                                                      _vm.getExtensionFile(
                                                                        data
                                                                          .item
                                                                          .fileName
                                                                      ) +
                                                                      ".svg"
                                                                }`,
                                                                width: "26",
                                                                height: "26",
                                                                id: _vm.generateTextId(
                                                                  `view-${data.item.id}`
                                                                ),
                                                              },
                                                            }),
                                                            _c(
                                                              "b-tooltip",
                                                              {
                                                                attrs: {
                                                                  target:
                                                                    _vm.generateTextId(
                                                                      `view-${data.item.id}`
                                                                    ),
                                                                  placement:
                                                                    "left",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      data.item
                                                                        .fileName
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(edit)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "btn bg-transparent border-0",
                                                        attrs: {
                                                          variant: "primary",
                                                          size: "sm",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editAccomodation(
                                                              data.item,
                                                              data.index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.EDIT.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.EDIT
                                                                  .color,
                                                              size: 20,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "table-busy",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center text-success my-2",
                                                      },
                                                      [
                                                        _c("b-spinner", {
                                                          staticClass:
                                                            "align-middle",
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            4169315282
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.$screen.width >= 992
                              ? _c(
                                  "b-row",
                                  { staticClass: "pb-2" },
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "pl-5 pr-5",
                                            attrs: {
                                              size: "md",
                                              variant: "custom-outline-gray",
                                              disabled:
                                                _vm.accomodationIdsToDelete
                                                  .length === 0,
                                            },
                                            on: {
                                              click:
                                                _vm.handleDeleteAccomodation,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(62, "Delete")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-modal",
                              {
                                staticClass: "modal-success",
                                attrs: {
                                  "header-class":
                                    "header-class-modal-doc-package",
                                  title: this.FormMSG(104, "Success!"),
                                  "ok-variant": "success",
                                  "ok-only": "",
                                },
                                on: {
                                  ok: function ($event) {
                                    _vm.successModal = false
                                  },
                                },
                                model: {
                                  value: _vm.successModal,
                                  callback: function ($$v) {
                                    _vm.successModal = $$v
                                  },
                                  expression: "successModal",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        12121213,
                                        "The new accomodation has been saved."
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-modal",
                              {
                                staticClass: "modal-success",
                                attrs: {
                                  "header-class":
                                    "header-class-modal-doc-package",
                                  title: this.FormMSG(104, "Success!"),
                                  "ok-variant": "success",
                                  "ok-only": "",
                                },
                                on: {
                                  ok: function ($event) {
                                    _vm.successModalDelete = false
                                  },
                                },
                                model: {
                                  value: _vm.successModalDelete,
                                  callback: function ($$v) {
                                    _vm.successModalDelete = $$v
                                  },
                                  expression: "successModalDelete",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        12121212,
                                        "This accomodation has been deleted."
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c("form-modal", {
                              attrs: {
                                title: _vm.titleFormModal,
                                action: _vm.action,
                              },
                              on: {
                                "accomodation-form-modal:closed":
                                  _vm.onFormModalCancelled,
                                "accomodation-form-modal:new-accomodation-delivery-list":
                                  _vm.onAccomodationDeliveryAdded,
                                "accomodation-form-modal:all-accomodation-delivery":
                                  _vm.onAccomodationDeliveryRefreshed,
                              },
                              model: {
                                value: _vm.isAccomodationFormOpen,
                                callback: function ($$v) {
                                  _vm.isAccomodationFormOpen = $$v
                                },
                                expression: "isAccomodationFormOpen",
                              },
                            }),
                            _c("update-modal", {
                              attrs: { item: _vm.item, action: _vm.action },
                              on: {
                                "accomodation-update-form-modal:closed":
                                  _vm.onUpdateFormModalCancelled,
                                "accomodation-update-form-modal:reload":
                                  _vm.onAccomodationDeliveryRefreshed,
                              },
                              model: {
                                value: _vm.isAccomodationUpdateFormOpen,
                                callback: function ($$v) {
                                  _vm.isAccomodationUpdateFormOpen = $$v
                                },
                                expression: "isAccomodationUpdateFormOpen",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }